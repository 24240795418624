import { faDirections, faEnvelope, faMoneyBillAlt, faPhoneAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import SEO from "../components/SEO";
// import GoogleMap from "../components/GoogleMap";
import "../styles/main.scss";

const Contact: React.FC = () => {
    const getRandomInt = (max: number) => {
        return Math.floor(Math.random() * max);
    }

    return (
        <>
            <SEO
                title="Contact - Leading Apps"
                description="Maak een afspraak en ontdek hoe we jouw website, mobiele applicaties en marketing naar een hoger niveau tillen!"
                article={false}
            />

            <Header />

            <section id="heading">
                <div className="row g-0 blue-bg pb-5 pt-3">
                    <div className="col width-wrapper">
                        <ul>
                            <li>
                                <h1 className="medium-top bold white mt-5">
                                    We zijn altijd benieuwd naar jouw uitdagingen
                                </h1>
                                <h1 className="medium-bottom bold green pb-3 mb-5 pb-5">
                                    op het gebied van websites en apps
                                </h1>
                            </li>
                            <li >
                                <div className="section-spacer-top " ></div>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>

            <div className="width-wrapper-large">

                <section id="concreet">

                    <div className="row g-0 width-wrapper-large mpb-sm contact-cards">
                        <div className="col-12 col-md-2 contact-card">
                            <ul>
                                <li>
                                    <h3 className="bold black mt-4">
                                        <div className="mb-3">
                                            <FontAwesomeIcon icon={faMoneyBillAlt} />
                                        </div>
                                        <span>
                                            Offerte
                                        </span>
                                    </h3>
                                    <h5 className="grey mt-3">
                                        Kan het voor €{getRandomInt(7500)}?
                                    </h5>
                                </li>
                            </ul>
                        </div>
                        <div className="col-12 col-md-2 contact-card">
                            <ul>
                                <li>
                                    <h3 className="bold black mt-4">
                                        <div className="mb-3">
                                            <FontAwesomeIcon icon={faPhoneAlt} />
                                        </div>
                                        <span>
                                            Bellen
                                        </span>
                                    </h3>
                                    <h5 className="grey mt-3">
                                        +31 6 41 37 62 42
                                    </h5>
                                </li>
                            </ul>
                        </div>
                        <div className="col-12 col-md-2 contact-card">
                            <ul>
                                <li>
                                    <h3 className="bold black mt-4">
                                        <div className="mb-3">
                                            <FontAwesomeIcon icon={faEnvelope} />
                                        </div>
                                        <span>
                                            Mailen
                                        </span>
                                    </h3>
                                    <h5 className="grey mt-3">
                                        rowan@leadingapps.nl
                                    </h5>
                                </li>
                            </ul>
                        </div>
                        <div className="col-12 col-md-2 contact-card">
                            <ul>
                                <li>
                                    <h3 className="bold black mt-4 ">
                                        <div className="mb-3">
                                            <FontAwesomeIcon icon={faDirections} />
                                        </div>
                                        <span>
                                            Bezoeken
                                        </span>
                                    </h3>
                                    <h5 className="grey mt-3">
                                        Kloosterweg 39 <br />
                                        5711 GJ Someren
                                    </h5>
                                </li>
                            </ul>
                        </div>
                    </div>
                </section>

                <section id="location">

                    <div className="section-spacer-top" ></div>

                    {/* <div className="row g-0 width-wrapper-large mpb-sm">
                        <div className="col">
                            <ul>
                                <li>
                                    <h1 className="bold black mt-4 h-centered">
                                        <span >
                                            Hier werken we aan jouw <span className="orange-underline">applicatie</span>.
                                        </span>
                                    </h1>
                                </li>
                                <li>
                                    <div className="section-spacer-top" ></div>

                                    <div className="row width-wrapper googleMapsWrapper">
                                        <div className="col-12 col-md-10">
                                            <GoogleMap />
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div> */}
                </section>

                <div className="section-spacer" ></div>

            </div>

            <Footer />
        </>
    )
}

export default Contact;